import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Feedback.css';

const Feedback = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [contact, setContact] = useState('');
  const [status, setStatus] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!rating) {
      setStatus(t('feedback.ratingRequired'));
      return;
    }
    if (!feedback.trim()) {
      setStatus(t('feedback.feedbackRequired'));
      return;
    }

    try {
      const response = await fetch('https://your-worker.workers.dev/feedback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ rating, feedback, contact }),
      });

      if (response.ok) {
        setStatus(t('feedback.successMessage'));
        setTimeout(() => {
          setIsOpen(false);
          setStatus('');
          setRating(0);
          setFeedback('');
          setContact('');
        }, 2000);
      } else {
        setStatus(t('feedback.errorMessage'));
      }
    } catch (error) {
      setStatus(t('feedback.errorMessage'));
    }
  };

  if (!isOpen) {
    return (
      <button 
        className="feedback-button"
        onClick={() => setIsOpen(true)}
      >
        <i className="fas fa-comment"></i>
        {t('feedback.button')}
      </button>
    );
  }

  return (
    <div className="feedback-modal">
      <div className="feedback-content">
        <button 
          className="close-button"
          onClick={() => setIsOpen(false)}
        >
          ×
        </button>
        <h2>{t('feedback.title')}</h2>
        <form onSubmit={handleSubmit}>
          <div className="rating-container">
            <label>{t('feedback.ratingLabel')}</label>
            <div className="star-rating">
              {[1, 2, 3, 4, 5].map((star) => (
                <button
                  key={star}
                  type="button"
                  className={`star ${star <= rating ? 'active' : ''}`}
                  onClick={() => setRating(star)}
                >
                  <i className="fas fa-star"></i>
                </button>
              ))}
            </div>
          </div>

          <div className="form-group">
            <label>{t('feedback.feedbackLabel')}</label>
            <textarea
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              placeholder={t('feedback.feedbackPlaceholder')}
              rows="4"
            />
          </div>

          <div className="form-group">
            <label>{t('feedback.contactLabel')}</label>
            <input
              type="text"
              value={contact}
              onChange={(e) => setContact(e.target.value)}
              placeholder={t('feedback.contactPlaceholder')}
            />
          </div>

          {status && <div className="status-message">{status}</div>}

          <button type="submit" className="submit-button">
            {t('feedback.submit')}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Feedback;
