import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { JSONTree } from 'react-json-tree';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import jsonlint from 'jsonlint-mod';
import './JsonFormatter.css';

const JsonFormatter = () => {
  const { t } = useTranslation();
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');
  const [error, setError] = useState('');
  const [showTips, setShowTips] = useState(true);
  const [viewMode, setViewMode] = useState('formatted'); // 'formatted', 'tree', 'compressed'
  const [parsedJson, setParsedJson] = useState(null);

  const validateAndParseJson = useCallback((jsonString) => {
    try {
      if (!jsonString.trim()) {
        setError('');
        setOutput('');
        setParsedJson(null);
        return null;
      }

      // 使用 jsonlint 进行语法验证
      const parsed = jsonlint.parse(jsonString);
      setError('');
      return parsed;
    } catch (e) {
      setError(e.message);
      return null;
    }
  }, []);

  const formatJson = useCallback(() => {
    const parsed = validateAndParseJson(input);
    if (parsed) {
      const formatted = JSON.stringify(parsed, null, 2);
      setOutput(formatted);
      setParsedJson(parsed);
    }
  }, [input, validateAndParseJson]);

  const compressJson = useCallback(() => {
    const parsed = validateAndParseJson(input);
    if (parsed) {
      const compressed = JSON.stringify(parsed);
      setOutput(compressed);
      setParsedJson(parsed);
    }
  }, [input, validateAndParseJson]);

  const copyToClipboard = useCallback(async () => {
    if (!output) {
      setError(t('formatter.errors.nothingToCopy'));
      return;
    }
    try {
      await navigator.clipboard.writeText(output);
      // 显示临时成功消息
      const tempError = error;
      setError(t('formatter.success.copied'));
      setTimeout(() => setError(tempError), 2000);
    } catch {
      setError(t('formatter.errors.copyFailed'));
    }
  }, [output, error, t]);

  const clearAll = useCallback(() => {
    setInput('');
    setOutput('');
    setError('');
    setParsedJson(null);
  }, []);

  const loadSample = useCallback(() => {
    const sampleJson = {
      name: t('formatter.sampleJson.name'),
      features: {
        item1: t('formatter.sampleJson.features.item1'),
        item2: t('formatter.sampleJson.features.item2'),
        item3: t('formatter.sampleJson.features.item3'),
        item4: t('formatter.sampleJson.features.item4')
      },
      config: {
        theme: t('formatter.sampleJson.config.theme')
      }
    };
    const formatted = JSON.stringify(sampleJson, null, 2);
    setInput(formatted);
    setOutput(formatted);
    setParsedJson(sampleJson);
    setError('');
  }, [t]);

  // 自动格式化
  useEffect(() => {
    const timer = setTimeout(() => {
      if (input) {
        formatJson();
      }
    }, 300);
    return () => clearTimeout(timer);
  }, [input, formatJson]);

  // JSON树的主题
  const jsonTreeTheme = {
    scheme: 'monokai',
    base00: '#272822',
    base01: '#383830',
    base02: '#49483e',
    base03: '#75715e',
    base04: '#a59f85',
    base05: '#f8f8f2',
    base06: '#f5f4f1',
    base07: '#f9f8f5',
    base08: '#f92672',
    base09: '#fd971f',
    base0A: '#f4bf75',
    base0B: '#a6e22e',
    base0C: '#a1efe4',
    base0D: '#66d9ef',
    base0E: '#ae81ff',
    base0F: '#cc6633'
  };

  return (
    <div className="page-container">
      <header className="header">
        <h1>{t('formatter.title')}</h1>
        <p className="subtitle">{t('formatter.subtitle')}</p>
      </header>

      {showTips && (
        <div className="tips-container">
          <div className="tips-header">
            <i className="fas fa-lightbulb icon"></i>
            {t('formatter.tips.title')}
            <button className="close-tips" onClick={() => setShowTips(false)}>
              ×
            </button>
          </div>
          <ul className="tips-list">
            {[0, 1, 2, 3].map((index) => (
              <li key={index}>{t(`formatter.tips.list.${index}`)}</li>
            ))}
          </ul>
        </div>
      )}

      <div className="formatter-container">
        <div className="toolbar">
          <button className="btn format" onClick={formatJson}>
            <i className="fas fa-indent icon"></i>
            {t('formatter.buttons.format')}
          </button>
          <button className="btn compress" onClick={compressJson}>
            <i className="fas fa-compress-alt icon"></i>
            {t('formatter.buttons.compress')}
          </button>
          <button className="btn copy" onClick={copyToClipboard}>
            <i className="fas fa-copy icon"></i>
            {t('formatter.buttons.copy')}
          </button>
          <button className="btn clear" onClick={clearAll}>
            <i className="fas fa-trash-alt icon"></i>
            {t('formatter.buttons.clear')}
          </button>
          <button className="btn sample" onClick={loadSample}>
            <i className="fas fa-file-code icon"></i>
            {t('formatter.buttons.loadSample')}
          </button>
          <div className="view-mode-buttons">
            <button
              className={`btn ${viewMode === 'formatted' ? 'active' : ''}`}
              onClick={() => setViewMode('formatted')}
            >
              <i className="fas fa-align-left icon"></i>
              {t('formatter.views.formatted')}
            </button>
            <button
              className={`btn ${viewMode === 'tree' ? 'active' : ''}`}
              onClick={() => setViewMode('tree')}
            >
              <i className="fas fa-sitemap icon"></i>
              {t('formatter.views.tree')}
            </button>
            <button
              className={`btn ${viewMode === 'compressed' ? 'active' : ''}`}
              onClick={() => setViewMode('compressed')}
            >
              <i className="fas fa-compress icon"></i>
              {t('formatter.views.compressed')}
            </button>
          </div>
        </div>

        <div className="editor-container">
          <div className="input-section">
            <textarea
              className="json-input"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder={t('formatter.placeholders.input')}
              spellCheck="false"
            />
          </div>

          {error && (
            <div className="error-message">
              <i className="fas fa-exclamation-circle"></i>
              {error}
            </div>
          )}

          <div className="json-view-container">
            <div className="view-header">
              {t('formatter.jsonView.title')}
            </div>
            <div className="view-content">
              {parsedJson && viewMode === 'tree' && (
                <JSONTree
                  data={parsedJson}
                  theme={jsonTreeTheme}
                  invertTheme={false}
                  shouldExpandNode={() => true}
                />
              )}
              {parsedJson && viewMode !== 'tree' && (
                <SyntaxHighlighter
                  language="json"
                  style={vs2015}
                  customStyle={{
                    margin: 0,
                    padding: '15px',
                    background: 'transparent',
                  }}
                >
                  {viewMode === 'compressed'
                    ? JSON.stringify(parsedJson)
                    : JSON.stringify(parsedJson, null, 2)}
                </SyntaxHighlighter>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="guide-container">
        <h1 className="guide-title">{t('formatter.guide.title')}</h1>
        
        {/* 简介部分 */}
        <section className="guide-section">
          <h2 className="guide-section-title">{t('formatter.guide.introduction.title')}</h2>
          <p className="guide-content">{t('formatter.guide.introduction.content')}</p>
        </section>

        {/* 功能特点 */}
        <section className="guide-section">
          <h2 className="guide-section-title">{t('formatter.guide.features.title')}</h2>
          <div className="feature-grid">
            <div className="feature-item">
              <h3 className="feature-title">
                <i className="fas fa-indent feature-icon"></i>
                {t('formatter.guide.features.format.title')}
              </h3>
              <p className="feature-description">{t('formatter.guide.features.format.content')}</p>
            </div>
            <div className="feature-item">
              <h3 className="feature-title">
                <i className="fas fa-compress-alt feature-icon"></i>
                {t('formatter.guide.features.compress.title')}
              </h3>
              <p className="feature-description">{t('formatter.guide.features.compress.content')}</p>
            </div>
            <div className="feature-item">
              <h3 className="feature-title">
                <i className="fas fa-check-circle feature-icon"></i>
                {t('formatter.guide.features.validate.title')}
              </h3>
              <p className="feature-description">{t('formatter.guide.features.validate.content')}</p>
            </div>
            <div className="feature-item">
              <h3 className="feature-title">
                <i className="fas fa-sitemap feature-icon"></i>
                {t('formatter.guide.features.treeView.title')}
              </h3>
              <p className="feature-description">{t('formatter.guide.features.treeView.content')}</p>
            </div>
          </div>
        </section>

        {/* 使用步骤 */}
        <section className="guide-section">
          <h2 className="guide-section-title">{t('formatter.guide.steps.title')}</h2>
          <ul className="steps-list">
            <li className="step-item">
              <h3 className="step-title">{t('formatter.guide.steps.input.title')}</h3>
              <p className="step-description">{t('formatter.guide.steps.input.content')}</p>
            </li>
            <li className="step-item">
              <h3 className="step-title">{t('formatter.guide.steps.operation.title')}</h3>
              <p className="step-description">{t('formatter.guide.steps.operation.content')}</p>
            </li>
            <li className="step-item">
              <h3 className="step-title">{t('formatter.guide.steps.result.title')}</h3>
              <p className="step-description">{t('formatter.guide.steps.result.content')}</p>
            </li>
          </ul>
        </section>

        {/* 快捷键 */}
        <section className="guide-section">
          <h2 className="guide-section-title">{t('formatter.guide.shortcuts.title')}</h2>
          <div className="shortcuts-grid">
            <div className="shortcut-item">
              <div className="shortcut-keys">
                <span className="key">Ctrl</span>
                <span className="key">Shift</span>
                <span className="key">F</span>
              </div>
              <span className="shortcut-description">{t('formatter.guide.shortcuts.format')}</span>
            </div>
            <div className="shortcut-item">
              <div className="shortcut-keys">
                <span className="key">Ctrl</span>
                <span className="key">Shift</span>
                <span className="key">M</span>
              </div>
              <span className="shortcut-description">{t('formatter.guide.shortcuts.compress')}</span>
            </div>
            <div className="shortcut-item">
              <div className="shortcut-keys">
                <span className="key">Ctrl</span>
                <span className="key">C</span>
              </div>
              <span className="shortcut-description">{t('formatter.guide.shortcuts.copy')}</span>
            </div>
            <div className="shortcut-item">
              <div className="shortcut-keys">
                <span className="key">Ctrl</span>
                <span className="key">D</span>
              </div>
              <span className="shortcut-description">{t('formatter.guide.shortcuts.clear')}</span>
            </div>
          </div>
        </section>

        {/* 提示和警告 */}
        <section className="guide-section">
          <h2 className="guide-section-title">{t('formatter.guide.tips.title')}</h2>
          <div className="guide-tip">
            <i className="fas fa-lightbulb tip-icon"></i>
            <div className="tip-content">
              {t('formatter.guide.tips.treeView.content')}
            </div>
          </div>
          <div className="guide-warning">
            <i className="fas fa-exclamation-triangle warning-icon"></i>
            <div className="warning-content">
              {t('formatter.guide.tips.largeData.content')}
            </div>
          </div>
        </section>
      </div>

      <footer className="footer">
        <div className="footer-content">
          <p>{t('formatter.footer.copyright')}</p>
          <div className="footer-links">
            <a href="#features">{t('formatter.footer.features')}</a>
            <a href="#about">{t('formatter.footer.about')}</a>
            <a href="#contact">{t('formatter.footer.contact')}</a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default JsonFormatter;