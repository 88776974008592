import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { LANGUAGES } from '../i18n/i18n';
import './LanguageSwitcher.css';

function LanguageSwitcher() {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const { lang } = useParams();
  const [isOpen, setIsOpen] = useState(false);

  const handleLanguageChange = (newLang) => {
    // 更新URL和语言
    if (newLang === 'en') {
      navigate('/', { replace: true });
    } else {
      navigate(`/${newLang}`, { replace: true });
    }
    
    // 确保在URL更新后更改语言
    setTimeout(() => {
      i18n.changeLanguage(newLang);
    }, 0);

    setIsOpen(false);
  };

  const currentLang = lang || 'en';
  const currentLanguage = LANGUAGES[currentLang];

  return (
    <div className="language-switcher">
      <button
        className="language-button"
        onClick={() => setIsOpen(!isOpen)}
        title={t('common.language.tooltip')}
        dir={currentLanguage.dir}
      >
        <span className="language-flag">
          {currentLanguage.flag}
        </span>
        <span className="language-name">
          {currentLanguage.nativeName}
        </span>
        <span className="language-arrow">
          {isOpen ? '▲' : '▼'}
        </span>
      </button>

      {isOpen && (
        <div className="language-dropdown" dir="ltr">
          {Object.entries(LANGUAGES).map(([code, { name, nativeName, flag }]) => (
            <button
              key={code}
              className={`language-option ${code === currentLang ? 'active' : ''}`}
              onClick={() => handleLanguageChange(code)}
              dir={LANGUAGES[code].dir}
            >
              <span className="language-flag">{flag}</span>
              <span className="language-native">{nativeName}</span>
              <span className="language-english">{name}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export default LanguageSwitcher;
