import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import JsonFormatter from './components/JsonFormatter';
import LanguageSwitcher from './components/LanguageSwitcher';
import MetaManager from './components/MetaManager';
import Feedback from './components/Feedback';
import { LANGUAGES } from './i18n/i18n';
import './App.css';
import './styles/rtl.css';

// 语言路由组件
function LanguageRoute() {
  const { lang } = useParams();
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    // 如果URL中的语言有效，则切换语言
    if (lang && Object.keys(LANGUAGES).includes(lang)) {
      i18n.changeLanguage(lang);
      // 设置文档的方向
      const dir = LANGUAGES[lang].dir || 'ltr';
      document.documentElement.dir = dir;
      document.body.dir = dir;
    } else if (lang) {
      // 如果语言无效，重定向到默认语言
      navigate('/', { replace: true });
      document.documentElement.dir = 'ltr';
      document.body.dir = 'ltr';
    }
  }, [lang, i18n, navigate]);

  // 使用一个包装器div来控制内容的方向
  const currentLang = lang || 'en';
  const dir = LANGUAGES[currentLang].dir || 'ltr';

  return (
    <div className="App" dir={dir}>
      <MetaManager />
      <header className="app-header">
        <div className="app-title">
          <span className="app-logo">{ LANGUAGES[currentLang].flag }</span>
          <h1>{t('formatter.title')}</h1>
        </div>
        <LanguageSwitcher />
      </header>
      <main className="fade-in">
        <div className="card">
          <JsonFormatter />
        </div>
        <Feedback />
      </main>
    </div>
  );
}

// Root组件
function Root() {
  return (
    <Routes>
      <Route path="/" element={<LanguageRoute />} />
      <Route path="/:lang" element={<LanguageRoute />} />
    </Routes>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Root />
    </BrowserRouter>
  );
}

export default App;
