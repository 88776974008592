import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

function MetaManager() {
  const { i18n, t } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    // 更新网页标题
    document.title = t('meta.title', 'JSON Parser & Formatter - Online JSON Tools');

    // 更新meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', t('meta.description'));
    } else {
      const newMetaDescription = document.createElement('meta');
      newMetaDescription.name = 'description';
      newMetaDescription.content = t('meta.description');
      document.head.appendChild(newMetaDescription);
    }

    // 更新meta keywords
    const metaKeywords = document.querySelector('meta[name="keywords"]');
    if (metaKeywords) {
      metaKeywords.setAttribute('content', t('meta.keywords'));
    } else {
      const newMetaKeywords = document.createElement('meta');
      newMetaKeywords.name = 'keywords';
      newMetaKeywords.content = t('meta.keywords');
      document.head.appendChild(newMetaKeywords);
    }

    // 更新语言标签
    document.documentElement.lang = lang || 'en';
  }, [i18n.language, t, lang]);

  // 返回null，因为这个组件不需要渲染任何内容
  return null;
}

export default MetaManager;
